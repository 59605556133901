"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// |-------------------------------|
// | This is code of a web worker. |
// | This code should remain       |
// | dependency free.              |
// |-------------------------------|
var message_1 = require("./shared/message");
var createArrayGrid_1 = require("./calculateRmZones/createArrayGrid");
var calculateClassification_1 = require("./calculateRmZones/calculateClassification");
onmessage = function (e) {
    if (isCalculateMessage(e)) {
        var payload_1 = e.data.payload;
        var combinedClassification_1 = {};
        try {
            payload_1.panelsByGroupId.map(function (_a) {
                var groupId = _a[0], panels = _a[1];
                var grid = createArrayGrid_1.createArrayGrid(panels, payload_1.gridSettings);
                var classification = calculateClassification_1.calculateClassification(grid, payload_1.classificationSettings);
                combinedClassification_1 = Object.assign({}, combinedClassification_1, classification);
            });
            var resultMessage = {
                classification: combinedClassification_1,
            };
            postMessage(resultMessage, null);
        }
        catch (err) {
            var errorMessage = {
                error: err.toString(),
            };
            postMessage(errorMessage, null);
        }
    }
};
var isCalculateMessage = function (e) {
    return e !== undefined && e.data !== undefined && e.data.type !== undefined && e.data.type === message_1.CALCULATE_RM_ZONES;
};
