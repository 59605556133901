"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// |-------------------------------|
// | This is code of a web worker. |
// | This code should remain       |
// | dependency free.              |
// |-------------------------------|
var zoneClassification;
(function (zoneClassification) {
    zoneClassification[zoneClassification["northCorner"] = 33] = "northCorner";
    zoneClassification[zoneClassification["southCorner"] = 32] = "southCorner";
    zoneClassification[zoneClassification["eastWestEdge"] = 31] = "eastWestEdge";
    zoneClassification[zoneClassification["northEdge"] = 13] = "northEdge";
    zoneClassification[zoneClassification["southEdge"] = 12] = "southEdge";
    zoneClassification[zoneClassification["field"] = 11] = "field";
    zoneClassification[zoneClassification["unknown"] = 0] = "unknown";
    zoneClassification[zoneClassification["northSouthCornerOverlap"] = 34] = "northSouthCornerOverlap";
    zoneClassification[zoneClassification["northSouthEdgeOverlap"] = 14] = "northSouthEdgeOverlap";
})(zoneClassification = exports.zoneClassification || (exports.zoneClassification = {}));
