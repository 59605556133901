"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
// |-------------------------------|
// | This is code of a web worker. |
// | This code should remain       |
// | dependency free.              |
// |-------------------------------|
var get_1 = require("../common/get");
var zoneClassification_1 = require("./zoneClassification");
var createArrayGrid_1 = require("./createArrayGrid");
var DEFAULT_THRESHOLD_FEET = 10;
var DEFAULT_THRESHOLD_INCHES = DEFAULT_THRESHOLD_FEET * 12;
exports.calculateClassification = function (grid, settings) {
    var fillArray = grid
        .map(function (row) { return row.map(function (cell) { return cell > 0 ? 1 : 0; }); });
    var columnCount = fillArray[0].map(function () { return ({
        modulesOn: 0,
        distanceOff: 0,
    }); });
    var returnArray = fillArray.map(function (row, i) {
        return row.map(function (cell, j) {
            return {
                id: grid[i][j],
                classification: zoneClassification_1.zoneClassification.unknown,
            };
        });
    });
    var length = settings.moduleLength;
    var width = settings.moduleWidth + settings.rowSpacing;
    // from left to rigth set classification
    fillArray.forEach(function (row, i) {
        var currentRowCount = {};
        row.forEach(function (cell, j) {
            var currentColumnCount = columnCount[j];
            fillMarker(cell, currentRowCount, length);
            fillMarker(cell, currentColumnCount, width);
            var eParam;
            var lParam;
            if (cell) {
                eParam = '1';
                lParam = '1';
                if (currentRowCount.modulesOn <= settings.eastWestEdgeDepth) {
                    eParam = '3';
                }
                if (currentColumnCount.modulesOn <= settings.northSouthEdgeDepth) {
                    lParam = '3';
                }
                returnArray[i][j] = __assign(__assign({}, returnArray[i][j]), { classification: getClassification(eParam, lParam) });
            }
            else {
                returnArray[i][j] = __assign(__assign({}, returnArray[i][j]), { classification: zoneClassification_1.zoneClassification.unknown });
            }
        });
    });
    columnCount = fillArray[0].map(function () { return ({}); });
    fillArray.reverse().forEach(function (row, iRev) {
        var i = fillArray.length - iRev - 1;
        var currentRowCount = {};
        row.reverse().forEach(function (cell, jRev) {
            var j = row.length - jRev - 1;
            var currentColumnCount = columnCount[j];
            fillMarker(cell, currentRowCount, length);
            fillMarker(cell, currentColumnCount, width);
            if (cell) {
                var _a = parseClassification(returnArray[i][j].classification), lParam = _a.lParam, eParam = _a.eParam;
                if (currentRowCount.modulesOn <= settings.eastWestEdgeDepth) {
                    eParam = '3';
                }
                if (currentColumnCount.modulesOn <= settings.northSouthEdgeDepth) {
                    if (lParam === '3') {
                        // for smaller arrays when north and south panels overlap
                        lParam = '4';
                    }
                    else {
                        lParam = '2';
                    }
                }
                returnArray[i][j] = __assign(__assign({}, returnArray[i][j]), { classification: getClassification(eParam, lParam) });
            }
            else {
                returnArray[i][j] = __assign(__assign({}, returnArray[i][j]), { classification: zoneClassification_1.zoneClassification.unknown });
            }
        });
    });
    return returnArray.reduce(function (result, array) {
        return array.reduce(function (result, panel) {
            var _a;
            if (panel.id === createArrayGrid_1.EMPTY_CELL) {
                return result;
            }
            return __assign(__assign({}, result), (_a = {}, _a[panel.id] = panel.classification, _a));
        }, result);
    }, {});
};
var fillMarker = function (fullCell, fillCount, gapDistance) {
    if (fullCell) {
        fillCount.modulesOn = get_1.get(fillCount, 'modulesOn', 0) + 1;
        fillCount.distanceOff = 0;
    }
    else {
        fillCount.distanceOff = get_1.get(fillCount, 'distanceOff', 0) + gapDistance;
    }
    if (get_1.get(fillCount, 'distanceOff', 0) > DEFAULT_THRESHOLD_INCHES) {
        // it means that there's too big gap between panels so they have to form
        // a new classification
        //
        // C - corner, N - north edge, E - east/west edge, S - south edge
        // X - field
        //
        //         >10ft
        //       /----/
        //  CCNNCC    CCNNCC
        //  EEXXEE    EEXXEE
        //  CCSSSSSSSSSSSSCC
        //
        fillCount.modulesOn = 0;
    }
};
var getClassification = function (eParam, lParam) {
    // Expected hierarchy:
    // 1) 33XX - North Corner
    // 2) 32XX - South Corner
    // 3) 31XX - East and West Edge
    // 4) 13XX - North Edge
    // 5) 12XX - South Edge
    // 6) 11XX - Field
    // eg. if a module could be in the north corner or in south corner, show as north corner. 
    switch ("" + eParam + lParam) {
        case '33':
            return zoneClassification_1.zoneClassification.northCorner;
        case '32':
            return zoneClassification_1.zoneClassification.southCorner;
        case '31':
            return zoneClassification_1.zoneClassification.eastWestEdge;
        case '13':
            return zoneClassification_1.zoneClassification.northEdge;
        case '12':
            return zoneClassification_1.zoneClassification.southEdge;
        case '11':
            return zoneClassification_1.zoneClassification.field;
        case '34':
            return zoneClassification_1.zoneClassification.northSouthCornerOverlap;
        case '14':
            return zoneClassification_1.zoneClassification.northSouthEdgeOverlap;
        default:
            return zoneClassification_1.zoneClassification.unknown;
    }
};
var parseClassification = function (classification) {
    switch (classification) {
        case zoneClassification_1.zoneClassification.northCorner:
            return {
                eParam: '3',
                lParam: '3',
            };
        case zoneClassification_1.zoneClassification.southCorner:
            return {
                eParam: '3',
                lParam: '2',
            };
        case zoneClassification_1.zoneClassification.eastWestEdge:
            return {
                eParam: '3',
                lParam: '1',
            };
        case zoneClassification_1.zoneClassification.northEdge:
            return {
                eParam: '1',
                lParam: '3',
            };
        case zoneClassification_1.zoneClassification.southEdge:
            return {
                eParam: '1',
                lParam: '2',
            };
        case zoneClassification_1.zoneClassification.northSouthCornerOverlap:
            return {
                eParam: '3',
                lParam: '4',
            };
        case zoneClassification_1.zoneClassification.northSouthEdgeOverlap:
            return {
                eParam: '1',
                lParam: '4',
            };
        case zoneClassification_1.zoneClassification.field:
            return {
                eParam: '1',
                lParam: '1',
            };
        default:
            return {
                eParam: '0',
                lParam: '0',
            };
    }
};
