"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var products_1 = require("../common/products");
exports.EMPTY_CELL = -1;
exports.createArrayGrid = function (panels, settings) {
    var columnSpacing = products_1.isRMDT(settings.productId) ?
        settings.columnSpacingMeters / 2 :
        settings.columnSpacingMeters;
    var xMargin = 0.001 + settings.columnSpacingMeters + 0.05;
    var yMargin = 0.001 + settings.rowSpacingMeters + 0.05;
    var xValues = panels.map(function (panel) { return panel.x * settings.metersPerPixel; });
    var yValues = panels.map(function (panel) { return panel.y * settings.metersPerPixel; });
    var xMin = Math.min.apply(Math, xValues);
    var xMax = Math.max.apply(Math, xValues);
    var yMin = Math.min.apply(Math, yValues);
    var yMax = Math.max.apply(Math, yValues);
    var dxRaw = (xMax - xMin) / (settings.moduleWidthMeters + columnSpacing);
    var dyRaw = (yMax - yMin) / (settings.moduleLengthMeters + settings.rowSpacingMeters);
    var dx = Number(Math.round(dxRaw)) + 1;
    var dy = Number(Math.round(dyRaw)) + 1;
    var array = [];
    for (var i = 0; i < dy; i++) {
        array.push([]);
        for (var j = 0; j < dx; j++) {
            array[i].push(exports.EMPTY_CELL);
        }
    }
    panels.forEach(function (panel) {
        var column = Math.abs(Number(((xMin - panel.x * settings.metersPerPixel) / (settings.moduleWidthMeters + columnSpacing)).toFixed(4)));
        var row = Math.abs(Number(((yMin - panel.y * settings.metersPerPixel) / (settings.moduleLengthMeters + settings.rowSpacingMeters)).toFixed(4)));
        dx = Math.abs(column - Number(column.toFixed(1)));
        dy = Math.abs(row - Number(row.toFixed(1)));
        if (dx < xMargin && dy < yMargin) {
            var columnIndex = Number(Math.round(column));
            var rowIndex = Number(Math.round(row));
            array[rowIndex][columnIndex] = panel.id;
        }
    });
    return array;
};
