"use strict";
// |-------------------------------|
// | This is code of a web worker. |
// | This code should remain       |
// | dependency free.              |
// |-------------------------------|
// ALSO - this is duplication from __common/constants/products.
// Because we cannot use app's code in workers, but we may need to check 
// for specific products.
Object.defineProperty(exports, "__esModule", { value: true });
exports.products = {
    solarmount_hd: 2,
    rm: 8,
    solarmount_2: 9,
    solarmount_2_tilt: 99,
    sunframe: 5,
    solarmount_lt: 11,
    sfmEnhanced: 17,
    sfmInfinity: 18,
    rm_5: 14,
    rm_dt: 15,
    gft: 19,
    ula: 20,
    helioscope: 98,
    ecofoot2_plus: 24,
};
function isRMDT(productId) {
    return productId === exports.products.rm_dt;
}
exports.isRMDT = isRMDT;
function isEcoFoot2Plus(productId) {
    return productId === exports.products.ecofoot2_plus;
}
exports.isEcoFoot2Plus = isEcoFoot2Plus;
