"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// |-------------------------------|
// | This is code of a web worker. |
// | This code should remain       |
// | dependency free.              |
// |-------------------------------|
function get(object, keys, defaultVal) {
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return get(object, keys.slice(1));
    }
    return object === undefined ? defaultVal : object;
}
exports.get = get;
